@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500&family=Open+Sans:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply flex flex-row items-center justify-center space-x-2.5 px-8 min-w-[11.25rem] h-10 rounded-2xl text-white select-none bg-black font-sans font-medium transition-transform dark:bg-white dark:text-black active:scale-95 focus-visible:ring-1 focus-visible:ring-black dark:focus-visible:ring-white;
    }

    .link {
        @apply inline-flex flex-row items-center space-x-1 text-black dark:text-white transition-opacity hover:opacity-90;
    }

    .dot {
        @apply flex flex-row items-center before:shrink-0 before:inline-block before:w-[3px] before:h-[3px] before:bg-current before:rounded-full before:mr-4;
    }

    .check {
        @apply flex flex-row items-start before:shrink-0 before:mr-4 before:mt-1 before:inline-block before:h-[11px] before:w-[5px] before:border-r-[2px] before:border-b-[2px] before:border-solid before:border-current before:origin-bottom-left before:rotate-45;
    }

    .step {
        @apply flex flex-row items-center before:inline-block before:w-[32px] before:h-[2px] before:bg-black before:mr-4 dark:before:bg-white;
    }

    .num {
        @apply flex flex-row items-center before:text-xs before:content-[counter(lesson,_decimal-leading-zero)] before:inline-block before:font-mono before:mr-3 before:text-neutral-700 dark:before:text-neutral-400;
        counter-increment: lesson;
    }

    .quote {
        @apply before:align-text-top before:leading-[0.1] before:mr-1 before:content-[url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEyNV8xMTgpIj4KPHBhdGggZD0iTTEwIDExSDZDNS43MzQ3OCAxMSA1LjQ4MDQzIDEwLjg5NDYgNS4yOTI4OSAxMC43MDcxQzUuMTA1MzYgMTAuNTE5NiA1IDEwLjI2NTIgNSAxMFY3QzUgNi43MzQ3OCA1LjEwNTM2IDYuNDgwNDMgNS4yOTI4OSA2LjI5Mjg5QzUuNDgwNDMgNi4xMDUzNiA1LjczNDc4IDYgNiA2SDlDOS4yNjUyMiA2IDkuNTE5NTcgNi4xMDUzNiA5LjcwNzExIDYuMjkyODlDOS44OTQ2NCA2LjQ4MDQzIDEwIDYuNzM0NzggMTAgN1YxM0MxMCAxNS42NjcgOC42NjcgMTcuMzMzIDYgMTgiIHN0cm9rZT0iI0RBREFEQSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE5IDExSDE1QzE0LjczNDggMTEgMTQuNDgwNCAxMC44OTQ2IDE0LjI5MjkgMTAuNzA3MUMxNC4xMDU0IDEwLjUxOTYgMTQgMTAuMjY1MiAxNCAxMFY3QzE0IDYuNzM0NzggMTQuMTA1NCA2LjQ4MDQzIDE0LjI5MjkgNi4yOTI4OUMxNC40ODA0IDYuMTA1MzYgMTQuNzM0OCA2IDE1IDZIMThDMTguMjY1MiA2IDE4LjUxOTYgNi4xMDUzNiAxOC43MDcxIDYuMjkyODlDMTguODk0NiA2LjQ4MDQzIDE5IDYuNzM0NzggMTkgN1YxM0MxOSAxNS42NjcgMTcuNjY3IDE3LjMzMyAxNSAxOCIgc3Ryb2tlPSIjREFEQURBIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzEyNV8xMTgiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==)];
    }

    .gradient {
        @apply bg-[linear-gradient(94deg,#B78830_-3.93%,#D0A24B_10.7%,#FFF6AB_25.9%,#E5B962_36.59%,#FDDC7F_46.72%,#FEDA7A_56.85%,#E7B85E_70.92%,#CE9B40_87.81%,#C7993C_102.44%)];
    }

    .bg-text {
        @apply bg-clip-text [-webkit-text-fill-color:transparent];
    }

    .spark {
        @apply bg-[linear-gradient(94deg,#B78830_-3.93%,#D0A24B_10.7%,#FFF6AB_25.9%,#E5B962_36.59%,#FDDC7F_46.72%,#FEDA7A_56.85%,#E7B85E_70.92%,#CE9B40_87.81%,#C7993C_102.44%)]
    }

    .swiper-pagination {
        @apply flex flex-row text-center justify-center items-center !relative py-8
            [--swiper-pagination-bottom:0px]
            [--swiper-pagination-top:0px]
            [--swiper-pagination-bullet-horizontal-gap:0.75rem]
            [--swiper-pagination-bullet-size:3px]
            [--swiper-pagination-color:black]
            [--swiper-pagination-bullet-inactive-color:black]
            [--swiper-pagination-bullet-inactive-opacity:1]
            dark:[--swiper-pagination-color:white]
            dark:[--swiper-pagination-bullet-inactive-color:white];
    }

    .dotted {
        @apply bg-[radial-gradient(rgba(0,0,0,0.2)_1px,transparent_1px),radial-gradient(rgba(0,0,0,0.2)_1px,transparent_1px)]
            bg-[length:4px_4px] bg-[0_0,2px_2px] bg-transparent;
    }
}

@layer utilities {
    .outlined {
        @apply !bg-transparent rounded-2xl text-black border-2 border-black dark:border-white dark:text-white focus-visible:ring-offset-1 focus-visible:ring-offset-white dark:focus-visible:ring-offset-black;
    }

    .golden {
        @apply bg-[linear-gradient(93deg,#DD9221_0%,#CE9B40_5.5%,#FFF077_48.94%,#C7993C_93.48%,#DD9221_105.57%)];
    }

    .black {
        @apply bg-[linear-gradient(93deg,#000000_0%,#141414_5.5%,#2a2a2a_48.94%,#111111_93.48%,#000000_105.57%)];
    }

    .black-border {
        @apply !bg-[linear-gradient(180deg,#000000_3.12%,#080808_14.06%,#0f0f0f_22.4%,#131313_30.74%,#242424_39.05%,#cbcbcb_39.06%,#d9d9d9_52.08%,#a9a9a9_61.46%,#0d0d0d_67.19%,#0b0b0b_77.08%,#080808_88.54%,#000000_100%)];
    }

    .golden-border {
        @apply !bg-[linear-gradient(180deg,#CE9B40_3.12%,#DD9221_14.06%,#E7B85E_22.4%,#E5B962_30.73%,#FEBD59_39.05%,#FFF077_39.06%,#FFFBEE_52.08%,#FFF077_61.46%,#D0A24B_67.19%,#DD9221_77.08%,#C7993C_88.54%,#DD9221_100%)];
    }
}

.swiper-pagination-bullet {
    @apply transition-shadow;
}

.swiper-pagination-bullet-active {
    @apply ring-1 ring-black ring-offset-2 ring-offset-white dark:ring-white dark:ring-offset-black;
}
